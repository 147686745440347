import React from 'react';
import './MessageLoader.css'; // Asegúrate de importar el CSS
import { ThreeDots, Grid, ThreeCircles } from 'react-loader-spinner';

const MessageLoader = ({ loading, symbol = "dots", text="Consultando fuentes..." }) => {
  const renderLoader = () => {
    switch (symbol) {
      case "grid":
        return <Grid visible={true} height="30" width="30" color="#60C3B7" secondaryColor="#3a746d" ariaLabel="loading" />;
      case "circles":
        return <ThreeCircles visible={true} height="30" width="30" color="#60C3B7" secondaryColor="#3a746d" ariaLabel="loading" />;
      case "dots":
        return <ThreeDots visible={true} height="30" width="30" color="#3a746d" radius="9" ariaLabel="loading" />;
      default:
        return <ThreeDots visible={true} height="30" width="30" color="#3a746d" radius="9" ariaLabel="loading" />;
    }
  };

  return (
    loading && (
      <div className="loading-container">
        {renderLoader()}
        <p className="loading-text">{text}</p>
      </div>
    )
  );
};

export default MessageLoader;

