// El primer parámetro del reducer es el estado actual, en este caso se renombra como documents.
export default function currentMessageReducer(message, action) {
  switch (action.type) {
    
    case "reset": 
        return {};

        case "set": {
          const elementoActual = document.querySelector(`[messageId="${action.message.id}"]`);
          if (elementoActual) {
            const contenedorActual = elementoActual.parentElement;
            const contenedorAnterior = contenedorActual.previousElementSibling;
            if (contenedorAnterior && contenedorAnterior.querySelector('[messageId]')) {
              const mensajeAnterior = contenedorAnterior.querySelector('[messageId]');
              const shown_documents = Array.from(elementoActual.children[2].children).map(child => child.getAttribute("documentId")).filter(documentId => documentId !== null);
              const hidden_documents = Array.from(elementoActual.children[2].lastChild.firstChild.children).map(child => child.getAttribute("documentId")).filter(documentId => documentId !== null);            
              const documents = [...shown_documents, ...hidden_documents];
              const humanMessage = {
                id: mensajeAnterior.getAttribute('messageId'),
                content:mensajeAnterior.textContent,
                documents: documents
                }
              return humanMessage;
            }
          }
          return {};
        }

        default:
          throw new Error("Unhadled action" + action.type);
}
}
