import { useEffect, useState } from 'react';
import { useDocumentsContext } from '../../../contexts/documentsContext';
import { usePreviewContext } from '../../../contexts/previewContext';
import getSourceIcon from '../../../services/helpers/getSourceIcon';
import './DocumentElement.css';
import { useCurrentMessageContext } from '../../../contexts/currentMessageContext';
import { usePostHog } from 'posthog-js/react';

const DocumentElement = ({ message, doc }) => {
    const { preview, dispatchPreview } = usePreviewContext();
    const { documents, dispatchDocuments } = useDocumentsContext();
    const { dispatchCurrentMessage } = useCurrentMessageContext();
    const posthog = usePostHog();
    const [isSelected, setIsSelected] = useState(false)
    
    useEffect(() => {
      console.log("CAMBIO")

      if (doc.doc_id && documents[doc.doc_id]) {
        if (documents[doc.doc_id].selected) {
          setIsSelected(true);
        } else {
          setIsSelected(false);
        }
      }
    }, [documents[doc.doc_id]]);
  
 
    const handleDocumentClick = (doc) => {
      posthog.capture('Fuentes - Acceso al panel de fuentes', {
        docId: doc.doc_id,
        source: doc.metadata?.source || doc.metadatas?.source || 'Fuente desconocida',
        title: doc.metadata?.title || doc.metadatas?.title || 'Sin título',
      });

      if (isSelected) {
        dispatchCurrentMessage({ type: 'set', message: message });
        // dispatchPreview({ type: 'unselect', selectedDoc: doc });
        dispatchDocuments({ type: 'unselect', selectedDocuments: [doc] })
        setIsSelected(false)
      } else {
        dispatchCurrentMessage({ type: 'set', message: message });
        dispatchPreview({ type: 'select', selectedDoc: doc });
        dispatchDocuments({ type: 'select', selectedDocuments: [doc] })
        setIsSelected(true)
      }

    };

    const handleShowPreviewClick = (doc) => {
      dispatchPreview({ type: 'set', selectedDoc: doc });
    }
  
    const index = doc.doc_id ? documents[doc.doc_id]?.index || '' : '';
    const source = doc.metadata?.source || doc.metadatas?.source || 'Fuente desconocida';
    const title = doc.metadata?.title || doc.metadatas?.title || 'Sin título';
    const score = doc.metadata?.score || doc.metadatas?.score || '';
    // const iconSrc = getSourceIcon(source);
    const iconSrc = require('../../../assets/images/4d-logo.png')

    return (
        <div key={doc.doc_id} documentId={doc.doc_id} className="breadcrumb-item">
          <div className={`document-element ${isSelected ? 'element-selected' : ''}`} onClick={() => handleDocumentClick(doc)}>
            <div className='document-row'>
              <div className='icon-source'>
                <img src={iconSrc} alt={source} className="document-icon" />
                <p className="document-source">{source}</p>
                <p className="document-score">{parseInt(score*100) + "%"}</p>
              </div>
              <strong className="document-index">{index}</strong>
            </div>
            <div className='document-row'>
              <p className="document-title">{title.slice(0, 60)+"..."}</p>
            </div>
          </div>
        </div>
      );
      
  };

export default DocumentElement