import './Auth.css';
import LoginForm from '../../components/forms/LoginForm/LoginForm';
import Footer from '../../components/containers/Footer/Footer';

function Auth() {
  return (
    <div className="auth">
      <LoginForm />
    </div>
  );
}

export default Auth;