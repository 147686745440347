import React, { createContext, useContext, useEffect, useReducer } from 'react';
import metadatasReducer from '../reducers/metadatasReducer';
import dummyAllMetadatas from '../services/dummy/metadatasOptions';
// Creamos el contexto
const MetadatasContext = createContext(null);
// Definimos el endpoint de la api sin parámetros

let initialMetadatas;
try {
  initialMetadatas = {
    allMetadatas: dummyAllMetadatas,
    selectedMetadatas: {}
  };
} catch {
  console.error("The preview could not be parsed into JSON");
  initialMetadatas = {};
}

// Proveedor del contexto de todas las fuentes presentes en la conversación
export function MetadatasProvider(props) {
  const [metadatas, dispatchMetadatas] = useReducer(metadatasReducer, initialMetadatas);
  useEffect(()=> localStorage.setItem("metadatas", JSON.stringify(metadatas)),[metadatas]);

  const contextValue = {
    metadatas,
    dispatchMetadatas,
  };

  return (
    <MetadatasContext.Provider value={contextValue}>
      {props.children}
    </MetadatasContext.Provider>
  );
}

// Hook personalizado para acceder al contexto
export function useMetadatasContext() {
  const context = useContext(MetadatasContext);
  if (!context) {
    throw new Error(
      "useMetadatasContext must be used within a MetadatasProvider. Wrap a parent component in <MetadatasProvider> to fix this error."
    );
  }
  return context;
}
