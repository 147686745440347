import React, { useEffect, useState, useRef } from 'react';
import './Chat.css';
import Sidebar from '../../components/containers/Sidebar/Sidebar';
import Header from '../../components/containers/Header/Header';
import ChatArea from '../../components/containers/ChatArea/ChatArea';
import PreviewArea from '../../components/containers/PreviewArea/PreviewArea';
import ChatInput from '../../components/forms/ChatInput/ChatInput';
import useChat from '../../hooks/useChat';
import { useLocation, useParams } from 'react-router-dom';
import { usePreviewContext } from '../../contexts/previewContext';
import { useConversationContext } from '../../contexts/conversationContext';
import { CurrentMessageProvider } from '../../contexts/currentMessageContext';
import { CollectionsProvider, useCollectionsContext} from '../../contexts/collectionsContext'
import { WEBSOCKET_CHAT_URL } from '../../services/api/websocketUrls';
import { useDocumentsContext } from '../../contexts/documentsContext';

const Chat = () => {
  const { id } = useParams();
  const location = useLocation(); // Hook para obtener la ubicación actual (incluye query string)
  const { messages, loadingConversation, loadingMessage, addMessage, addWebsocketMessage, fetchMessages } = useChat();
  const { preview } = usePreviewContext();
  const { conversation } = useConversationContext();
  const { documents, dispatchDocuments } = useDocumentsContext();
  const { current_collection } = useCollectionsContext();

  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [newMessages, setMessages] = useState(messages); // Estado local para manejar los mensajes
  const socketRef = useRef(null); // Usamos useRef para evitar recrear la conexión
  const [wsLoading, setWsLoading] = useState(false);
  
  const SOCKET_SERVER_URL = `${WEBSOCKET_CHAT_URL}/${id}/`;
  const searchParams = new URLSearchParams(location.search)
  const question = searchParams.get("q")

  useEffect(() => {
    // Function to clean up existing WebSocket connection
    const cleanupWebSocket = () => {
      if (socketRef.current) {
        socketRef.current.close();
        socketRef.current = null;
      }
    };

    // Close the previous WebSocket connection before opening a new one
    cleanupWebSocket();

    // Create a new WebSocket connection
    const ws = new WebSocket(SOCKET_SERVER_URL);
    socketRef.current = ws;

    ws.onopen = () => {
      console.log(`Connected to WebSocket: ${SOCKET_SERVER_URL}`);
      sendQuestionMessage()
    };
      
    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.content === "[[START]]") {
        setWsLoading(true);
        return;
      }
      if (data.content === "[[END]]") {
        setWsLoading(false);
        return;
      }

      const message = {
        msg_id: data.message_id || "temporal",
        msg_content: data.content,
        msg_type: data.role,
        msg_metadata: {
          intention: data.response_metadata?.intention || "",
          documents: data.response_metadata?.documents || [],
          loader_message:  data.response_metadata?.loader_message || null,
          loader_symbol:  data.response_metadata?.loader_symbol || null,
          remove_when_next_message:  data.response_metadata?.remove_when_next_message || false
        }
      };
      if (message) {
        dispatchDocuments({ type: 'add', responseMessage: message });
      }

      if (message?.msg_metadata?.loader_message) {
        setWsLoading(false);
      }
     
      setMessages((prevMessages) => {
        
        if (!prevMessages) return [message]; // Si no hay mensajes previos, agregar el primero
      
        const messageExists = prevMessages.some((msg) => msg.msg_id === message.msg_id);
      
        let updatedMessages;
        if (messageExists) {
          // Si el mensaje ya existe, actualizarlo
          updatedMessages = prevMessages.map((msg) => 
            msg.msg_id === message.msg_id ? { ...msg, ...message } : msg
          );
        } else {
          // Si no existe, agregarlo
          updatedMessages = [...prevMessages, message];
        }
      
        // Filtrar mensajes con `remove_when_next_message === true`, pero solo si no son el último mensaje
        updatedMessages = updatedMessages.filter((msg, index, array) => {
          const isLastMessage = index === array.length - 1;
          return isLastMessage || !msg.msg_metadata.remove_when_next_message;
        });
      
        return updatedMessages;
      });

      
    };

    ws.onclose = () => {
      console.log("Disconnected from WebSocket");
    };

    // Cleanup function: Close WebSocket when component unmounts or `id` changes
    return cleanupWebSocket;
  }, [id]); // Dependency array ensures this runs when `id` changes

  useEffect(() => {
    if (id) {
      fetchMessages(id); // Cargar mensajes iniciales
    }
  }, [id]);

  useEffect(() => {
    // Verifica si preview es un objeto y tiene propiedades
    const isPreviewObject = typeof preview === 'object' && preview !== null && !Array.isArray(preview);
    const isPreviewArray = Array.isArray(preview) && preview.length > 0;
  
    if (isPreviewObject || isPreviewArray) {
      setIsSidebarVisible(false);  // Oculta el sidebar si preview es un objeto con propiedades o un array no vacío
    } else {
      setIsSidebarVisible(true);   // Muestra el sidebar si preview es un objeto vacío o un array vacío
    }
  }, [preview]);

  // Sincronizar los mensajes locales con conversation.history
  useEffect(() => {
    setMessages(conversation.history); // Actualiza los mensajes con el historial de la conversación
  }, [conversation.history]);

  const toggleSidebar = () => {
    setIsSidebarVisible(prev => !prev);
  };
  
  useEffect(() => {
    sendQuestionMessage()
  }, [question]); // El array vacío [] asegura que este efecto solo se ejecute una vez, similar a componentDidMount

  const sendQuestionMessage = () => {
    if (question && socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      const messageObject = {
        agent: "general",
        message: question,
        intention: "rag_question",
        kwargs: {
          collection_name: current_collection,
          doc_ids: [], 
          metadatas: {},
          related_message: "",
          external_searches: [],
          intention: "rag_question"},  // Ejemplo de ID de usuario
      };
      socketRef.current.send(JSON.stringify(messageObject));

    }
  }

  return (
    <>
    <div className={`chat-container ${isSidebarVisible ? '' : 'sidebar-hidden'}`}>
    <CurrentMessageProvider>
      
      <Sidebar toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible} />
      <div className={`chat-content ${!isSidebarVisible ? 'full-width' : ''}`}>
        <Header toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible} title={"Consulta | "} subtitle={conversation.name} />
        <div className={`chat-preview-container ${!preview ? 'full-width' : ''}`}>
          <ChatArea conversation_id={id} messages={newMessages} loading={loadingConversation} socketRef={socketRef} />
        </div>
        <ChatInput socketRef={socketRef} loading={wsLoading} disabled={false}/>

        {/* {Object.keys(preview).length === 0 ? (
            <ChatInput onSend={addMessage} socketRef={socketRef} loading={wsLoading} disabled={false}/>
          ) : (
            <ChatInput onSend={addMessage} socketRef={socketRef} loading={wsLoading} disabled={true} />
          )} */}        

      </div>
      {preview ? <PreviewArea /> : <div className="preview-area hidden" />}

    </CurrentMessageProvider>
    
    </div>
    </>

  );
};

export default Chat;
