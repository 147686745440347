import React from 'react';
import './AiMessage.css';
import MessageToolbar from '../MessageToolbar/MessageToolbar';
import DocumentCarrousel from '../DocumentsCarrousel/DocumentsCarrousel';
import MessageTextBox from '../MessageText/MessageTextBox';
import useChat from '../../../hooks/useChat';
import MessageLoader from '../../loaders/MessageLoader/MessageLoader';

const AiMessage = ({ id, message, metadata, conversation_id }) => {  
  const messageInfo = {
    id: id,
  };

  return (
    <div messageId={id} className="ai-message-box">
      <div className="response-container">
        <div className="response-header">
          <div className="title-ai-answer">
            <img
              // src={require('../../../assets/images/logo-temp-pomelo.png')}
              src={require('../../../assets/images/4d-logo.png')}

              alt="Pomelo Logo"
              className="answer-logo"
            />
            <h2 className="answer-title">Respuesta</h2>
          </div>
          <div className="message-metadata-container">
            <small>{metadata?.created ? new Date(metadata?.created).toLocaleString() : 'N/A'}</small>
          </div>
        </div>
        {metadata?.loader_message ? (
          <div className="response-body">
            <MessageTextBox message={message} />
            <MessageLoader loading={true} text={metadata?.loader_message} symbol={metadata?.loader_symbol} />
          </div>
        ) : (
          <div className="response-body">
            <MessageTextBox message={message} />
          </div>
        )}


      </div>
      <MessageToolbar
        message={message}
        documents={metadata?.documents}
        conversation_id={conversation_id}
        message_id={id}
      />
      {metadata?.documents && (
        <DocumentCarrousel message={messageInfo} documents={metadata?.documents} />
      )}

    </div>
  );
};

export default AiMessage;
