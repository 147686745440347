import './CitationIcon.css';

const CitationIcon = ({ number, doc, onClick }) => {
  const tooltip = doc?.metadata?.title ?? 'No title available';

  return (
    <span
      className={`citation-icon ${doc ? 'has-tooltip' : ''}`}
      data-tooltip={doc ? tooltip : ''}
      onClick={onClick}
      role="button"
      aria-label={`Citation ${number}: ${tooltip}`}
      tabIndex={0} // Permite navegación con teclado
    >
      {number}
    </span>
  );
};

export default CitationIcon;
