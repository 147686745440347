import { useState } from 'react';
import apiRequest from '../services/api/apiRequest';

const useFeedback = () => {
  const [feedback, setBookmark] = useState([]);
  const [loading, setLoading] = useState(false); // Para manejar el estado de carga
  const [error, setError] = useState(null); // Para manejar los errores

  const addFeedback = async (conversation_id, message_id, feedback_type, comments) => {
    setError(null); // Limpia cualquier error previo

    try {
        const { data, error: apiError } = await apiRequest(
            '/feedback/',
            'POST',
            'include',
            'application/json',
            {conversation: conversation_id,  message_id: message_id, feedback_type: feedback_type, comments: comments}
        );

        if (apiError) {
            throw new Error(apiError.message);
        }

    } catch (error) {
        console.error('Error adding feedback:', error);
        setError(error.message); // Establece el mensaje de error
    } finally {
    }
  };

  const fetchFeeback = async (feedback) => {
    setLoading(true); // Inicia el estado de carga
    setError(null); // Limpia cualquier error previo

    try {
        setBookmark(() => []);
        const { data, error: apiError } = await apiRequest(
            '/feedback',
            'GET',
            'include',
            'application/json'
        );

        if (apiError) {
            throw new Error(apiError.message);
        }
        
        if (data && Array.isArray(data)) {
          setBookmark(data);
        }
        

    } catch (error) {
        console.error('Error fetching feedback:', error);
        setError(error.message); // Establece el mensaje de error
    } finally {
        setLoading(false); // Termina el estado de carga
    }
  };

  return {
    feedback,
    loading,
    addFeedback,
    fetchFeeback,
  };
};

export default useFeedback;
