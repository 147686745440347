import React from 'react';
import "./Footer.css"; 
import { FaCopyright } from 'react-icons/fa6';

const Footer = () => {

  return (
    <footer className='footer'>
      <div className='container footer-content flex'>
        <div className='footer-left'>
          <p className='footer-p'> Desarrollado por <a href="https://www.databot.es">Databot Technologies</a></p>

        </div>
        <div className='footer-right'>
          <div className='footer-links'>
            <ul className='footer-nav'>
              <li className='footer-item margins'>
                <a href='mailto:help@databot.es?subject=Incidencia%20-%Pomelo' className='footer-link'>Informar de incidencia</a>
              </li>
              <li className='footer-item margins'>
                <a href='/about' className='footer-link'>Aviso Legal</a>
              </li>
              <li className='footer-item margins'>
                <a href='/contact' className='footer-link'>Política de Protección de datos</a>
              </li>

            </ul>
          </div>

        </div>

      </div>
    </footer>
  )
}

export default Footer
