import React, { useState } from 'react';

const DynamicFormMessage = () => {

  const formData = {
    "form_name": "Tareas Pendientes",
    "description": "Formulario para registrar tareas pendientes",
    "fields": [
        {
            "name": "tarea",
            "type": "string",
            "label": "Descripción de la tarea",
            "placeholder": "Descripción breve de la tarea pendiente"
        },
        {
            "name": "prioridad",
            "type": "select",
            "label": "Prioridad",
            "options": ["Alta", "Media", "Baja"]
        },
        {
            "name": "fecha_limite",
            "type": "date",
            "label": "Fecha Límite",
            "placeholder": "Selecciona una fecha de vencimiento"
        },
        {
            "name": "estado",
            "type": "select",
            "label": "Estado actual",
            "options": ["Pendiente", "En progreso", "Completada"]
        },
        {
            "name": "notas",
            "type": "text",
            "label": "Notas adicionales",
            "placeholder": "Información relevante o comentarios"
        }
    ]
}
  const [formState, setFormState] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Formulario enviado:", formState);
  };

  const renderField = (field) => {
    switch (field.type) {
      case 'string':
        return (
          <input
            type="text"
            name={field.name}
            placeholder={field.placeholder || ''}
            onChange={handleChange}
            value={formState[field.name] || ''}
          />
        );

      case 'text':
        return (
          <textarea
            name={field.name}
            placeholder={field.placeholder || ''}
            onChange={handleChange}
            value={formState[field.name] || ''}
          />
        );

      case 'select':
        return (
          <select
            name={field.name}
            onChange={handleChange}
            value={formState[field.name] || ''}
          >
            <option value="">Seleccione una opción</option>
            {field.options.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        );

      case 'date':
        return (
          <input
            type="date"
            name={field.name}
            onChange={handleChange}
            value={formState[field.name] || ''}
          />
        );

      default:
        return null;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>{formData.form_name}</h2>
      <p>{formData.description}</p>
      {formData.fields.map((field, index) => (
        <div key={index} style={{ marginBottom: '1rem' }}>
          <label>
            {field.label}:
            {renderField(field)}
          </label>
        </div>
      ))}
      <button type="submit">Enviar</button>
    </form>
  );
};

export default DynamicFormMessage;
