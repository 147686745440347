function stateToQueryMetadatas(metadatas) {
    if (!metadatas) return {};

    const filters = Object.entries(metadatas).map(([key, metadata]) => ({
        [key]: { $in: metadata.values.map(v => v.value) }
    }));

    return filters.length > 1 ? { "$and": filters } : filters[0] || {};
}

export default stateToQueryMetadatas;
