import React from 'react';
import ButtonWelcome from '../../buttons/ButtonWelcome/ButtonWelcome';
import './Welcome.css';
import useRedirect from '../../../hooks/useRedirect';

const Welcome = () => {

  const redirectTo = useRedirect();

  const handleButtonClick = () => {
    redirectTo('/chat');
  };

  return (
    <div className="welcome-container">
      <img 
        src={require('../../../assets/images/4d-logo.png')}
        // src={require('../../../assets/images/logo-temp-pomelo.png')} 
        alt="Pomelo Logo" 
        className="welcome-logo" 
      />
      <h1 className="welcome-title">
        La plataforma de conocimiento científico especializado impulsada con inteligencia artificial
      </h1>
      <p className="welcome-description">
        Responda sus preguntas clínicas con evidencia actualizada, validada y contrastable.
      </p>
      <ButtonWelcome text="Acceder" onClick={handleButtonClick} />
    </div>
  );
};

export default Welcome;
