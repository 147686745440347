
import Select from 'react-select'; // Asegúrate de tener react-select instalado

import React, { useCallback, useEffect, useState } from 'react';
import debounce from '../../../services/helpers/debounce';

const FormInput = ({ name, type, label, icon, initial_value, placeholder, helptext, onChange, options, isMulti=false, wait_time=2000, showHelpButton=false }) => {
    
    const [showHelp, setShowHelp] = useState(false);
    const [value, setValue] = useState();

    const toggleHelp = () => {
        setShowHelp(prevState => !prevState);
    };

    const handleChange = (e) => {
      if (onChange) {
        if (type === "select") {
          onChange(e); // Pasar directamente el valor del select (puedes adaptarlo según uses react-select)
          setValue(e);
        } else {
          onChange(e.target.value); // Para inputs estándar
          setValue(e.target.value);
        }
      }
    };

    const handleChangeDebounced = useCallback(
      debounce((value) => {
        if (onChange) {
          onChange(value); // Debounce para manejar eventos de escritura
        }
      }, wait_time),
      [onChange]
    );

    const handleTextAreaChange = (e) => {
      setValue(e.target.value);
      handleChangeDebounced(e.target.value); // Actualiza con debounce
    };

    useEffect(() => {
      setValue(initial_value)
    },[])

    return (
      <div className="modal-section">
        {showHelpButton && label && <label className="description-title" htmlFor={name}>{label} <button type="button" onClick={toggleHelp} className="help-icon">❓</button></label> }
        {helptext && (
            <div className="help-container">
            {showHelp && <small className="">{helptext}</small>}
            </div>
        )}
        <div className="search-container">
          {icon && <img src={icon} alt="Buscar" className="search-icon" />}
          
          {type === "textarea" && (
          <textarea
            id={name}
            name={name}            
            placeholder={placeholder}
            value={value} // El valor es controlado externamente
            onChange={handleTextAreaChange} // Actualiza el valor al escribir
          />
          )}
                  
          {type === "select" && (
            <Select
              options={options}
              value={value}
              onChange={handleChange}
              isMulti={isMulti}
              placeholder={placeholder}
              styles={{
                control: (base) => ({
                  ...base,
                  backgroundColor: 'var(--textfield-bg)',
                  borderColor: 'var(--textfield-border)',
                  color: 'var(--text-primary)',
                  borderRadius: '6px',
                  height: '48px',
                  padding: '0 4px',
                  marginTop: '6px',
                }),
                singleValue: (base) => ({
                  ...base,
                  color: 'var(--text-primary)',
                }),
                menu: (base) => ({
                  ...base,
                  backgroundColor: 'var(--dropdown-bg);',
                  border: 'var(--dropdown-border)',
                  borderRadius: '8px',
                  padding: '12px',
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isFocused ? 'var(--dropdown-option-bg-hover);' : '',
                  color: 'var(--dropdown-option-text)',
                  padding: '10px 12px',
                  borderRadius: state.isFocused ? '6px' : '0',
                  cursor: 'pointer',
                }),
              }}
            />
          )}
        </div>
      </div>
    );
  };
  
  export default FormInput;
