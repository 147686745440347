import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ProtectedRoutes } from './components/routes/ProtectedRoutes/ProtectedRoutes';
import  Auth   from './pages/auth/Auth';
import Home  from './pages/home/Home';
import Account from './pages/account/Account';
import PasswordReset from './pages/password-reset/PasswordReset';
import Bookmarks from './pages/bookmarks/Bookmarks';
import Chat from './pages/chat/Chat';
import Sources from './pages/sources/Sources';
import Playground from './pages/playground/Playground';

export const AppRouter = () => {
    return (
      <Router>
        <Routes>
            <Route path="login" element={<Auth pageTitle="Iniciar Sesión - Pomelo" />}/>
            <Route path="password-reset" element={<PasswordReset pageTitle="Contraseña olvidada - Pomelo" />} />
            <Route path="/" element={<Home pageTitle="Home - Pomelo" />} />
            <Route element={<ProtectedRoutes />}>
                <Route path="chat" element={<Chat pageTitle="Chat - Pomelo" />} />
                <Route path="chat/:id" element={<Chat pageTitle="Chat - Pomelo" />} />
                <Route path="bookmarks" element={<Bookmarks pageTitle="Favoritos - Pomelo" />} />
                <Route path="account" element={<Account pageTitle="Mi cuenta - Pomelo" />} />
                <Route path="sources" element={<Sources pageTitle="Fuentes" />} />
                <Route path="Playground" element={<Playground pageTitle="Playground" />} />
            </Route>
        </Routes>
      </Router>
    );
  };
  