import './Bookmarks.css';

import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/containers/Sidebar/Sidebar';
import useBookmark from '../../hooks/useBookmark';
import BookmarkArea from '../../components/containers/BookmarkArea/BookmarkArea';
import Header from '../../components/containers/Header/Header';
import { usePreviewContext } from '../../contexts/previewContext';
import PreviewArea from '../../components/containers/PreviewArea/PreviewArea';
import { CurrentMessageProvider } from '../../contexts/currentMessageContext';

const Bookmarks = () => {
  const { bookmarks, loading, fetchBookmarks } = useBookmark();
  const { preview } = usePreviewContext();
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  useEffect(() => {
    fetchBookmarks();  // Cargar bookmarks solo una vez al montar el componente
  }, []); 

  const toggleSidebar = () => {
    setIsSidebarVisible(prev => !prev);
  };
  return (
  <div className={`chat-container ${isSidebarVisible ? '' : 'sidebar-hidden'}`}>
  <Sidebar toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}/>
  <div className={`chat-content ${!isSidebarVisible ? 'full-width' : ''}`}>
    <Header subtitle={"Favoritos"} toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible} />
    <div className={`chat-preview-container ${!preview ? 'full-width' : ''}`}>
    <CurrentMessageProvider>
      <BookmarkArea bookmarks={bookmarks} loading={loading} />
      {preview ? <PreviewArea /> : <div className="preview-area hidden" />}
    </CurrentMessageProvider>
    </div>
  </div>
  </div>
  );
};

export default Bookmarks;