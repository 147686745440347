import React, { useState } from 'react';
import './NoLikeButton.css';
import { RiCloseFill } from "react-icons/ri";
import { ReactComponent as Iconnolike } from '../../../assets/images/icons/icon-nolike.svg';
import useFeedback from '../../../hooks/useFeedback';


const FeedbackNoLike = ({ onClose, conversation_id, message_id, onFeedbackSent }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const { feedback, loading, addFeedback } = useFeedback();

  const options = [
    'Difícil de entender',
    'Incorrecta o engañosa',
    'No responde a mi pregunta',
    'Demasiado breve o incompleta',
    'Demasiado extensa',
    'Desactualizada',
    'Tono inapropiado u ofensivo',
    'Errores ortográficos o gramaticales',
    'Otros',
  ];

  const toggleOption = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((o) => o !== option)); 
    } else {
      setSelectedOptions([...selectedOptions, option]); 
    }
  };

  const handleSendFeedback = async () => {
    // Llamada a addFeedback y esperar respuesta
    addFeedback(conversation_id, message_id, 2, selectedOptions);
    // Cerrar el modal
    onClose();
    // Llamar a onFeedbackSent para actualizar el estado en el componente padre
    onFeedbackSent();
  };

  return (
    <div className="modal-overlay">
      <div className="modal-feedback">
        <div className="modal-header">
          <h2>¡Ayúdanos a mejorar!</h2>
          <button className="close-btn" onClick={onClose}>
            <RiCloseFill size={24} />
          </button>
        </div>

        <div className="modal-body">
          <div className="modal-section">
            <p className="modal-question">
              Cuéntanos por qué esta respuesta no cumplió con tus expectativas. Marca todas las opciones que correspondan.
            </p>

            <div className="feedback-options">
              {options.map((option) => (
                <div
                  key={option}
                  className={`feedback-option ${selectedOptions.includes(option) ? 'selected' : ''}`}
                  onClick={() => toggleOption(option)}
                >
                  {option}
                </div>
              ))}
            </div>
          </div>

          <div className="modal-section">
            <p className="freetext">
              ¿Cómo podemos mejorar la respuesta? 
              <p className="opcional">(Opcional)</p>
            </p>
            <textarea placeholder="Comenta o añade detalles específicos." />
          </div>
        </div>

        <div className="modal-footer">
          <button className="cancel-btn" onClick={onClose}>
            Cancelar
          </button>
          <button className="primary-btn" onClick={handleSendFeedback}>
            Enviar Feedback
          </button>
        </div>
      </div>
    </div>
  );
};


const NoLikeButton = ({ message, conversation_id, message_id }) => {
  const [showModal, setShowModal] = useState(false);
  const [disliked, setDisliked] = useState(false); // Estado para saber si el feedback fue enviado

  const handleNoLike = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleFeedbackSent = () => {
    // Cambiar el estado de disliked para añadir la clase al icono
    setDisliked(true);
  };

  return (
    <div title="No me ha sido útil">
      <button onClick={handleNoLike} className={`nolike-button ${disliked ? 'disliked' : ''}`}>
        <Iconnolike className="nolike-button-icon"/>
      </button>
      {showModal && (
        <FeedbackNoLike 
          conversation_id={conversation_id} 
          message_id={message_id} 
          onClose={closeModal} 
          onFeedbackSent={handleFeedbackSent}
        />
      )}
    </div>
  );
};
export default NoLikeButton;