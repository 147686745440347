import React, { useState } from 'react';
import './CopyMessageButton.css'; 
import { ReactComponent as IconCopy } from '../../../assets/images/icons/icon-copy.svg';
import ButtonTooltip from '../ButtonTooltip/ButtonTooltip';

const CopyMessageButton = ({ message }) => {
  const [copied, setCopied] = useState(false);

  const formatMessage = (msg) => {
    return msg.replace(/\[\[(\d+)\]\]/g, '[$1]');
  };

  const handleCopy = () => {
    const formattedMessage = formatMessage(message);
    
    navigator.clipboard.writeText(formattedMessage)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1500);
      })
      .catch(err => {
        console.error('Error al copiar el mensaje: ', err);
      });
  };

  return (
    <div className="copy-message-button" onClick={handleCopy} title="Copiar mensaje">
      <IconCopy className={`copy-message-icon ${copied ? 'copied' : ''}`}  />
    </div>
  );
};

export default CopyMessageButton;
