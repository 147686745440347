import Welcome from '../../components/containers/Welcome/Welcome';
import './Home.css';

function Home() {
  return (
    <div className="App">
      <Welcome></Welcome>
    </div>
  );
}

export default Home;