// El primer parámetro del reducer es el estado actual, en este caso se renombra como metadatas.
export default function metadatasReducer(metadatas, action) {
  switch (action.type) {
    case "RESET": 
        return {};

    // case "add": {
    //   const metadataToAdd = { index: 1, ...action.message };            
    //   // Retornar un nuevo estado inmutable sin modificar el original
    //   const updatedMetadatas = {
    //     ...metadatas, // Copia del estado actual
    //     metadatas: [...metadatas, metadataToAdd], // Copia del historial con el nuevo mensaje
    //   };
    //   return updatedMetadatas; // Devuelve la copia modificada del estado
    // }

    case "SET_SELECTED": {
      return {allMetadatas: metadatas.allMetadatas, selectedMetadatas: action.selectedMetadatas};
    }

    default:
    throw new Error("Unhadled action" + action.type);
  }
}