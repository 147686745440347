import React, { useEffect, useState, useRef } from 'react';
import './FiltersInputModal.css';
import iconDelete from '../../../assets/images/icons/icon-delete.svg';
import { RiCloseFill } from "react-icons/ri";
import FormInput from '../FormInput/FormInput';
import { useMetadatasContext } from '../../../contexts/metadatasContext';

const FiltersInputModal = ({ onClose }) => {
    const [selectedField, setSelectedField] = useState('');
    const { metadatas, dispatchMetadatas } = useMetadatasContext();
    const [fields, setFields] = useState(metadatas.selectedMetadatas);
    const modalRef = useRef(null); // Referencia para el modal

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose(); // Cierra el modal si se hace clic afuera
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

    const handleAddField = (selected) => {
        if (selected && !fields[selected.value]) {
            setFields(prevFields => ({
                ...prevFields,
                [selected.value]: { label: selected.label, values: [] }
            }));
        }
    };

    const handleDeleteField = (fieldName) => {
        setFields(prevFields => {
            const newFields = { ...prevFields };
            delete newFields[fieldName];
            return newFields;
        });
    };
    
    const handleFieldValueChange = (fieldName, values) => {
        setFields(prevFields => ({
            ...prevFields,
            [fieldName]: { 
                ...prevFields[fieldName], 
                values 
            }
        }));
    };
    
    useEffect(() => {
        dispatchMetadatas({ type: "SET_SELECTED", selectedMetadatas: fields });
    }, [fields]);

    return (
        <div className="modal-overlay">
            <div className="modal-filters" ref={modalRef}>
                <div className="modal-header">
                    <h2>Filtra la búsqueda para que sea más concreta</h2>
                    <button className="close-btn" onClick={onClose}>
                        <RiCloseFill size={24} />
                    </button>
                </div>

                <div className="modal-field-selection">
                    <FormInput
                        name={"metadatas"}
                        type={"select"}
                        label={"Filtro"}
                        options={Object.entries(metadatas.allMetadatas).map(([key, value]) => ({ label: value.label, value: key }))}
                        placeholder={"Selecciona el filtro"}
                        onChange={(e) => handleAddField(e)}
                    />                    
                </div>

                {Object.keys(fields).length > 0 && (
                    <div className="modal-body-filters">
                        {Object.entries(fields).map(([fieldName, field]) => (
                            <div key={fieldName} className="input-group">
                                <label>{field.label}</label>
                                <div className="input-with-icon">
                                    <FormInput
                                        name={"metadatas"}
                                        type={"select"}
                                        label={"Filtro"}
                                        isMulti={true}
                                        initial_value={metadatas.selectedMetadatas?.[fieldName]?.values || []}
                                        options={metadatas.allMetadatas[fieldName].values}
                                        placeholder={"Selecciona el filtro"}
                                        onChange={(e) => handleFieldValueChange(fieldName, e)}
                                    />
                                    {/* Botón con el icono para eliminar el campo */}
                                    <img 
                                        src={iconDelete} 
                                        alt="Eliminar campo" 
                                        className="icon-delete" 
                                        onClick={() => handleDeleteField(fieldName)} 
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                <div className="modal-footer"></div>
            </div>
        </div>
    );
};

export default FiltersInputModal;
