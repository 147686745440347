import React, { useState } from 'react';
import CompactedDocumentElement from '../CompactedDocumentElement/CompactedDocumentElement';
import './DocumentGroup.css'; // Add custom styles if necessary
import { usePostHog } from 'posthog-js/react'; // Importar PostHog
import { usePreviewContext } from '../../../contexts/previewContext';
import {useCurrentMessageContext} from '../../../contexts/currentMessageContext'
const documentElementLimit = 5;

const DocumentGroup = ({ documents, message }) => {
    const posthog = usePostHog(); // Inicializar PostHog
    const [showAll, setShowAll] = useState(false); // Estado para mostrar todos los documentos
    const { dispatchPreview } = usePreviewContext()
    const { dispatchCurrentMessage } = useCurrentMessageContext();


    // Manejar el clic en "Ver más"
    const handleViewMoreClick = () => {
        posthog.capture('Fuentes - Ver más documentos', {
            totalDocuments: documents.length,
            displayedDocuments: documentElementLimit,
        });
        setShowAll(true);
        dispatchCurrentMessage({ type: 'set', message: message });
        dispatchPreview({ type: 'set', selectedDoc: documents });
        
    };

    // Manejar acceso al panel de fuentes
    const handlePanelAccess = () => {
        posthog.capture('Fuentes - Acceso al panel de fuentes', {
            totalDocuments: documents.length,
        });
        setShowAll(true)
    };

    // Determinar documentos visibles
    const visibleDocuments = showAll ? documents : documents.slice(0, documentElementLimit);

    return (
        <div className="document-group" onClick={handleViewMoreClick}>
            <div className="document-group-elements">
                {visibleDocuments.map((doc) => (
                    <CompactedDocumentElement 
                        key={doc.index} 
                        doc={doc} 
                    />
                ))}
            </div>
            <div className='document-group-text'>
                {!showAll && documents.length > documentElementLimit && (
                    <b onClick={(e) => {
                        e.stopPropagation(); // Evitar disparar el evento del panel
                    }}>
                        {documents.length - documentElementLimit} más
                    </b>
                )}
            </div>
        </div>
    );
};

export default DocumentGroup;