import React, { createContext, useContext, useReducer, useEffect } from 'react';
import previewReducer from '../reducers/previewReducer';

// Creamos el contexto
const PreviewContext = createContext(null);
// Definimos el endpoint de la api sin parámetros

let initialPreview;
try {
  initialPreview = {};
} catch {
  console.error("The preview could not be parsed into JSON");
  initialPreview = {};
}

// Proveedor del contexto de la búsqueda
export function PreviewProvider(props) {
  const [preview, dispatchPreview] = useReducer(previewReducer, initialPreview);
  useEffect(()=> localStorage.setItem("preview", JSON.stringify(preview)),[preview]);

  const contextValue = {
    preview,
    dispatchPreview,
  };

  return (
    <PreviewContext.Provider value={contextValue}>
      {props.children}
    </PreviewContext.Provider>
  );
}

// Hook personalizado para acceder al contexto
export function usePreviewContext() {
  const context = useContext(PreviewContext);
  if (!context) {
    throw new Error(
      "usePreviewContext must be used within a PreviewProvider. Wrap a parent component in <PreviewProvider> to fix this error."
    );
  }
  return context;
}
