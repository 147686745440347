import React from 'react';
import './ActionHookButton.css';
import { FaFilePdf, FaEye, FaHeart, FaTrash, FaShareAlt } from "react-icons/fa"; // Añadimos los nuevos iconos
import { MdWebhook } from "react-icons/md";
import { useParams } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react'; // POSTHOG EXAMPLE
import useActionHook from '../../../hooks/useActionHook';
import { ReactComponent as IconShare } from '../../../assets/images/icons/icon-share.svg';
import { ReactComponent as IconFav } from '../../../assets/images/icons/icon-fav.svg';
import { ReactComponent as IconDelete } from '../../../assets/images/icons/icon-delete.svg';
import { ReactComponent as IconDownload } from '../../../assets/images/icons/icon-download.svg';

const ActionHookButton = ({ hook_type }) => {
    const { loading, sendActionHook } = useActionHook();
    const { id } = useParams();
    const posthog = usePostHog()

    const handlePrintConversationAction = () => {
        posthog?.capture('hook_print_conversation') // POSTHOG EXAMPLE
        sendActionHook(id, "print_conversation", {});
    }

    const handleViewAction = () => {
        posthog?.capture('hook_see_conversation') // POSTHOG EXAMPLE
        sendActionHook(id, "ver", {});
    }

    const handleShareAction = () => {
        posthog?.capture('hook_share_conversation') // POSTHOG EXAMPLE
        sendActionHook(id, "compartir", {});
    }

    const handleAddToFavoritesAction = () => {
        posthog?.capture('hook_addfav_conersation') // POSTHOG EXAMPLE
        sendActionHook(id, "añadir_favoritos", {});
    }

    const handleDeleteAction = () => {
        posthog?.capture('hook_delete_conversation') // POSTHOG EXAMPLE
        sendActionHook(id, "eliminar", {});
    }

    switch (hook_type) {
        case "print_conversation":
            return (
                <button title="Exportar conversación" className={`action-hook-button ${loading ? 'loading' : ''}`} onClick={handlePrintConversationAction} disabled={loading}>
                    <IconDownload className="hook-button-icon" />
                </button>
            );
        case "ver":
            return (
                <button title="Ver contenido" className={`action-hook-button ${loading ? 'loading' : ''}`} onClick={handleViewAction} disabled={loading}>
                    <FaEye className="action-hook-button-icon" />
                </button>
            );
        case "compartir":
            return (
                <button title="Compartir" className={`action-hook-button ${loading ? 'loading' : ''}`} onClick={handleShareAction} disabled={loading}>
                    <IconShare className="hook-button-icon" />
                </button>
            );
        case "añadir_favoritos":
            return (
                <button title="Añadir a favoritos" className={`action-hook-button ${loading ? 'loading' : ''}`} onClick={handleAddToFavoritesAction} disabled={loading}>
                    <IconFav className="hook-button-icon" />
                </button>
            );
        case "eliminar":
            return (
                <button title="Eliminar" className={`action-hook-button ${loading ? 'loading' : ''}`} onClick={handleDeleteAction} disabled={loading}>
                    <IconDelete className="hook-button-icon" />
                </button>
            );
        default:
            return (
                <button title="Acción no definida" className={`action-hook-button`}>
                    <MdWebhook className="action-hook-button-icon" />
                </button>
            );
    }
};

export default ActionHookButton;
