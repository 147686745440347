import React, { useState } from 'react';
import './LikeButton.css'; 
import useFeedback from '../../../hooks/useFeedback';
import { ReactComponent as Iconlike } from '../../../assets/images/icons/icon-like.svg';

const LikeButton = ({ message, conversation_id, message_id }) => {
  const [liked, setLiked] = useState(false);
  const { feedback, loading, addFeedback } = useFeedback();

  const handleLike = () => {
    // Pasamos los datos correctamente en formato de objeto
    addFeedback(conversation_id,message_id,1,{});
    setLiked(true); // Actualiza el estado para reflejar que el mensaje fue "likeado"
  };

  return (
    <div className="like-button" onClick={handleLike} title="Me ha sido útil">
      <Iconlike className={`like-button-icon ${liked ? 'liked' : ''}`} />
    </div>
  );
};

export default LikeButton;
