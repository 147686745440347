import React, { useEffect, useState, useRef } from "react";
import { WEBSOCKET_CHAT_URL } from "../../services/api/websocketUrls";

const SOCKET_SERVER_URL = WEBSOCKET_CHAT_URL + "/2e86efa8-1700-4a93-9003-5ec947ad8c5e/"

const Playground = () => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const socketRef = useRef(null); // Usamos useRef para evitar recrear la conexión

  useEffect(() => {
    // Verificar que no haya una conexión activa
    if (!socketRef.current) {
      const ws = new WebSocket(SOCKET_SERVER_URL);

      ws.onopen = () => {
        console.log("Conectado al servidor WebSocket");
      };

      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        setMessages((prevMessages) => [...prevMessages, data]);
      };

      ws.onclose = () => {
        console.log("Desconectado del servidor WebSocket");
      };

      socketRef.current = ws; // Guardamos la conexión en la referencia
    }

    // Cleanup: cerrar la conexión cuando el componente se desmonta
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
        socketRef.current = null;
      }
    };
  }, []);

  const sendMessage = () => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      const messageObject = {
        message: message,
        intention: "rag_question",
        kwargs: {
          collection_name: "Cardio-Renal-Syndrome",
          metadatas: {},
          intention: "rag_question",
          related_message: "",
        },  // Ejemplo de ID de usuario
      };
      socketRef.current.send(JSON.stringify(messageObject));
      setMessage("");
    } else {
      console.log("WebSocket no está abierto.");
    }
  };

  return (
    <div>
      <h2>Chat con WebSocket</h2>
      <ul>
        {messages.map((msg, index) => (
          <li key={index}>{msg.content}</li>
        ))}
      </ul>
      <input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <button onClick={sendMessage}>Enviar</button>
    </div>
  );
};

export default Playground;
