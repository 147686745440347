import React, { useState } from 'react';
import './HumanMessage.css';

const HumanMessage = ({ id, message, metadata }) => {
    const { intention, ...otherMetadata } = metadata;
    const [showMetadata, setShowMetadata] = useState(false);

    // Lista de claves que deseas excluir
    const excludedKeys = ["tenant", "loader_message", "loader_symbol",'remove_when_next_message','documents'];

    // Filtrar metadata excluyendo las claves no deseadas
    const filteredMetadata = Object.entries(otherMetadata).filter(
        ([key]) => !excludedKeys.includes(key)
    );

    const getTag = (intention) => {
        switch (intention) {
            case 'rag_question':
                return <span className="message-tag">Búsqueda en repositorio</span>;
            case 'doc_question':
                return <span className="message-tag">Pregunta a documento</span>;
            case 'context_question':
                return <span className="message-tag">Búsqueda externa</span>;
            case 'base_question':
                return <></>;
            default:
                return <></>;
        }
    };

    return (
      <>
        <div messageid={id} className="human-message-box">
          <div className="message-header">
            {getTag(intention)}
          </div>

          {/* Contenedor del mensaje y el botón */}
          <div className="message-content">
            <p className="message-text">{message}</p>
            {/* <button 
              onClick={() => setShowMetadata(!showMetadata)}
              className="toggle-metadata-btn"
            >
              +
            </button> */}
          </div>
        </div>
          {/* Div de metadata que se muestra o se oculta dinámicamente
          {showMetadata && filteredMetadata.length > 0 && (
            <div className="metadata-hidden">
              {filteredMetadata.map(([key, value]) => (
                <span key={key}><strong>{key}:</strong> {JSON.stringify(value)}</span>
              ))}
            </div>
          )} */}
      </>


    );
};

export default HumanMessage;
