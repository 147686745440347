import React, { useEffect, useState } from 'react';
import './Sidebar.css';
import NewQueryButton from '../../buttons/NewQueryButton/NewQueryButton';
import SidebarFooter from './SidebarFooter/SidebarFooter';
import useChatHistory from '../../../hooks/useChatHistory';
import { useNavigate } from 'react-router-dom';
import ToggleSidebarButton from '../../buttons/ToggleSidebarButton/ToggleSidebarButton';
import { useParams } from 'react-router-dom';
import SidebarConversationButton from '../../buttons/SidebarConversationButton/SidebarConversationButton';
import { useHistoryContext } from '../../../contexts/historyContext';
import { formatMonthYear } from '../../../services/helpers/sidebar';

const Sidebar = ({ toggleSidebar, isSidebarVisible }) => {
  const { history } = useHistoryContext();
  const { listConversations, loading } = useChatHistory();
  const navigate = useNavigate();
  const { id } = useParams();

  const [openGroups, setOpenGroups] = useState({
    today: true,
    thisWeek: false,
    lastWeek: false,
    thisMonth: false,
    lastMonths: false,
  });

  const [isMobile, setIsMobile] = useState(false);

  const sectionTranslations = {
    today: 'Hoy',
    thisWeek: 'Esta Semana',
    lastWeek: 'Semana Pasada',
    thisMonth: 'Este Mes',
    lastMonths: 'Meses Anteriores',
  };

  const sections = Object.keys(sectionTranslations);

  useEffect(() => {
    listConversations();
  }, [history.length]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleGroup = (group) => {
    setOpenGroups((prev) => ({
      ...prev,
      [group]: !prev[group],
    }));
  };

  // Verificación de que history es un objeto y no está vacío
  if (!history || Object.keys(history).length === 0) return null;

  return (
    <>
      {/* Overlay for modal behavior on mobile */}
      {isMobile && isSidebarVisible && (
        <div
          className="modal-overlay"
          onClick={toggleSidebar}
        ></div>
      )}
      <div
        className={`sidebar ${isSidebarVisible ? 'sidebar-expanded' : 'sidebar-collapsed'} ${
          isMobile ? (window.innerWidth <= 768 ? 'sidebar-modal' : 'sidebar-ipad') : ''
        }`}
      >
        <div className="sidebar-items">
          <div className="top-sidebar">
            <div className="logo-sidebar">
              <img
                // src={require('../../../assets/images/logo-temp-pomelo.png')}
                src={require('../../../assets/images/4d-logo.png')}
                alt="Pomelo Logo"
                className="isotipo-logo"
              />
              {/* <h2 className="title-logo">Pomelo</h2> */}
              <h2 className="title-logo">IA-4D</h2>

            </div>
            <ToggleSidebarButton onClick={toggleSidebar} />
          </div>
          
          <div className="button-container">
            <NewQueryButton loading={loading} />
          </div>
          <div className="sidebar-chat-items">
            {sections.map((section) => (
              <div key={section} className="item-group">
                <h3
                  onClick={() => toggleGroup(section)}
                  className={`item-group-header ${
                    openGroups[section] ? 'open' : 'closed'
                  }`}
                >
                  {sectionTranslations[section]}
                </h3>
                {openGroups[section] && (
                  <>
                    {section === 'lastMonths'
                      ? history[section] && Object.keys(history[section]).length > 0
                        ? Object.keys(history[section]).map((monthYear) => (
                            <div key={monthYear}>
                              <h3 className="item-group-header">
                                {formatMonthYear(monthYear)}
                              </h3>
                              {history[section][monthYear].map((conversation) => (
                                <SidebarConversationButton
                                  key={conversation.id}
                                  conversation={conversation}
                                  onClick={() => navigate(`/chat/${conversation.id}`)}
                                  active={id === conversation.id}
                                />
                              ))}
                            </div>
                          ))
                        : (
                            <p className="empty-conversation">
                              No hay conversaciones en {sectionTranslations[section].toLowerCase()}.
                            </p>
                          )
                      : history[section] && Object.keys(history[section]).length > 0
                      ? Object.values(history[section]).map((conversation) => (
                          <SidebarConversationButton
                            key={conversation.id}
                            conversation={conversation}
                            onClick={() => navigate(`/chat/${conversation.id}`)}
                            active={id === conversation.id}
                          />
                        ))
                      : (
                          <p className="empty-conversation">
                            No hay conversaciones en {sectionTranslations[section].toLowerCase()}.
                          </p>
                        )}
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
        <SidebarFooter />
      </div>
    </>
  );
};

export default Sidebar;