// src/components/NewQueryButton.jsx
import React, { useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import './NewQueryButton.css';
import { usePostHog } from 'posthog-js/react';
import NewQueryForm from '../../forms/NewQueryForm/NewQueryForm.jsx';

const NewQueryButton = ({ loading }) => {
  const [showModal, setShowModal] = useState(false);
  const posthog = usePostHog();

  const handleOpenModal = () => {
    setShowModal(true);
    
    posthog?.capture('NewQuery - Inicio de nueva consulta', {
      action: 'open_modal',
      timestamp: new Date(),
    });
  };

  const handleCloseModal = () => setShowModal(false);


  return (
    <>
      {/* Botón para abrir el modal */}
      <button
        className={`new-query-button ${loading ? 'loading' : ''}`}
        onClick={handleOpenModal}
        disabled={loading}
      >
        <FaPlus className="icon" />
        <span>{loading ? 'Cargando...' : 'Nueva consulta'}</span>
      </button>

      {/* Modal que se muestra cuando se hace clic en el botón */}
      {showModal && (
        <NewQueryForm show={showModal} onClose={handleCloseModal} />
      )}
    </>
  );
};

export default NewQueryButton;