export default function collectionsReducer(state = { current_collection: {}, collections: [] }, action) {
  switch (action.type) {
    case "RESET": 
      return { current_collection: {}, collections: [] };

    case "SET_COLLECTIONS": 
      
      return { ...state, collections: action.payload };

    case "SET_CURRENT_COLLECTION": {
      const selectedCollection = state.collections.find(col => col.id === parseInt(action.payload));
      if (!selectedCollection) {
        console.error(`Collection with ID ${action.payload} not found.`);
        return state;
      }
      return { ...state, current_collection: selectedCollection };
    }

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}
