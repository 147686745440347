import React, { useState } from 'react';
import Select from 'react-select';
  
const styles = {
  multiValue: (base, state) => {
    return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed
      ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
      : base;
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: 'none' } : base;
  },
};

const orderOptions = (values) => {
    if (!Array.isArray(values) || values.length === 0) {
      return [];
    }
    return values
      .filter((v) => v.isFixed)
      .concat(values.filter((v) => !v.isFixed));
  };  

const metadataOptions = [
    { value: 'source', label: 'Fuente', isFixed: true },
    { value: 'blue', label: 'Blue', isFixed: true },
    { value: 'green', label: 'Green', isFixed: false },
  ];

  const cleanedMetadataOptions = metadataOptions.map(({ options, ...rest }) => rest);


const InputFilter = () => {
  const [value, setValue] = useState(
    orderOptions([cleanedMetadataOptions[0], cleanedMetadataOptions[1]])
  );

  const onChange = (newValue, actionMeta) => {
    switch (actionMeta.action) {
      case 'remove-value':
      case 'pop-value':
        if (actionMeta.removedValue.isFixed) {
          return;
        }
        break;
      case 'clear':
        newValue = cleanedMetadataOptions.filter((v) => v.isFixed);
        break;
      default:
        break;
    }

    setValue(orderOptions(newValue));
  };

  return (
    <Select
      value={value}
      isMulti
      styles={styles}
      isClearable={value.some((v) => !v.isFixed)}
      name="colors"
      className="basic-multi-select"
      classNamePrefix="select"
      onChange={onChange}
      options={cleanedMetadataOptions}
    />
  );
};

export default InputFilter;
