import { usePostHog } from 'posthog-js/react';
import React, { useState, useEffect } from 'react';
import './NewQueryForm.css';
import { RiCloseFill } from "react-icons/ri";
import { ReactComponent as IconConsulta } from '../../../assets/images/icons/icon-consulta.svg';
import { ReactComponent as IconLock } from '../../../assets/images/icons/icon-lock.svg';
import { ReactComponent as IconMicroscope } from '../../../assets/images/icons/icon-microscope.svg';
import { ReactComponent as IconNotes } from '../../../assets/images/icons/icon-notes.svg';
import useChatHistory from '../../../hooks/useChatHistory';

const NewQueryForm = ({ show, onClose }) => {
  // const botOptions = { 'pomeloservices_general': 'General', 'pomeloservices_private': 'Búsqueda privada', 'pomeloservices_research': 'Búsqueda académica', 'pomeloservices_worknotes': 'Diario de trabajo' }; // Opciones de ChatBot
  const botOptions = { 
    'pomeloservices_general': { name: 'General', description: 'Asistente de consulta general.', icon: <IconConsulta className="icon-option" /> },
    'pomeloservices_private': { name: 'Búsqueda privada', description: 'Los chats se borran al cerrar la conversación.', icon: <IconLock className="icon-option" /> },
    // 'pomeloservices_academic': { name: 'Búsqueda académica', description: 'Enfocado en investigación y estudios.', icon: <IconMicroscope className="icon-option" /> },
    // 'pomeloservices_diary': { name: 'Diario de trabajo', description: 'Registro y seguimiento de tareas.', icon: <IconNotes className="icon-option" /> }
  };
  const options = {}

  const posthog = usePostHog()

  const [selectedPersistanceOption, setSelectedPersistanceOption] = useState(Object.keys(options)[0]);
  const [selectedBotOption, setSelectedBotOption] = useState(Object.keys(botOptions)[0]);
  const [title, setTitle] = useState('Consulta'); // Estado para el título de la conversación
  const { newConversation } = useChatHistory();

  const toggleFeedbackOption = (option) => {
    setSelectedPersistanceOption(option);
    posthog?.capture('NewQuery - Seleccionar tipo de almacenamiento', {
      tipoAlmacenamiento: options[option]
    });
  };

  const toggleBotOption = (option) => {
    setSelectedBotOption(option);
    posthog?.capture('NewQuery - Seleccionar tipo de ChatBot', {
      tipoChatbot: botOptions[option]
    });
  };

  const handleFeedbackSubmit = () => {
    posthog?.capture('NewQuery - Crear consulta', {
      tipoAlmacenamiento: options[selectedPersistanceOption],
      tipoChatbot: botOptions[selectedBotOption]
    });
    newConversation(title, selectedBotOption, selectedPersistanceOption);
    onClose();
  };

  const handleCloseButton = () => {
    posthog?.capture('NewQuery - Cerrar modal sin crear consulta');
    onClose();
  };

  const handleCancelButton = () => {
    posthog?.capture('NewQuery - Cancelar creación de consulta');
    onClose();
  };

  return (
    <div className="modal-overlay">
      <div className="modal-consulta">
        <div className="modal-header">
          <h2>Nueva Consulta</h2>
          <button className="close-btn" onClick={handleCloseButton}>
            <RiCloseFill size={24} />
          </button>
        </div>

        <div className="modal-body">
            <div className="modal-section">
                <p className="modal-question">Título de la conversación</p>
                <p className="description-title">
                    Un buen título te ayudará a identificar fácilmente esta conversación en el futuro.
                </p>
                <textarea 
                    placeholder="Escribe un título descriptivo..." 
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
            </div>
            {/* <div className="modal-section">
                <p className="modal-question">¿Quieres que el chat sea guardado o temporal?</p>
                <div className="feedback-options-consulta">
                    {Object.keys(options).map((key) => (
                    <div
                        key={key}
                        className={`feedback-option-consulta ${selectedPersistanceOption === key ? 'selected' : ''}`}
                        onClick={() => toggleFeedbackOption(key)}
                    >
                        {options[key]}
                        <p className="option-description">
                            {key === 'persist' 
                              ? 'La conversación se guardará para futuras referencias.' 
                              : 'La conversación se eliminará después de que finalices.'}
                        </p>
                    </div>
                    ))}
                </div>
            </div> */}
            <div className="modal-section">
                <p className="modal-question">¿Qué tipo de ChatBot quieres usar?</p>
                <div className="feedback-options-consulta">
                  {Object.keys(botOptions).map((key) => (
                        <div
                            key={key}
                            className={`feedback-option-consulta ${selectedBotOption === key ? 'selected' : ''}`}
                            onClick={() => toggleBotOption(key)}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                {botOptions[key].icon}
                                <span>{botOptions[key].name}</span>
                                <span className="bot-description">{botOptions[key].description}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>

        <div className="modal-footer">
          <button className="cancel-btn" onClick={handleCancelButton}>
            Cancelar
          </button>
          <button id="crear_consulta" className="primary-btn" onClick={handleFeedbackSubmit}>
            Crear consulta
          </button>
        </div>
      </div>
    </div>
    
  );
};

export default NewQueryForm;
