import React, { useState } from 'react';
import './MessagesPlaceholder.css'; // Asegúrate de crear este archivo CSS para los estilos
import LoaderImg from "../../../assets/images/logo-temp-pomelo.png"
import FormInput from '../FormInput/FormInput';
import { useCollectionsContext } from '../../../contexts/collectionsContext';
import useChatHistory from '../../../hooks/useChatHistory';
import { useNavigate, useParams } from 'react-router-dom';

const MessagesPlaceholder = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { newConversation } = useChatHistory();
  const { collections, current_collection, dispatchCollections } = useCollectionsContext();
  const [showForm, setShowForm] = useState(false);

  const questions = current_collection?.sample_questions?.sort(() => 0.5 - Math.random()).slice(0, 6) || [];
  const placeholder = current_collection?.placeholder_message

  const handleCollectionChange = (collection_id) => {
    dispatchCollections({ type: "SET_CURRENT_COLLECTION", payload: collection_id });
    setShowForm(false)
  };

  const toggleFormVisibility = () => {
    setShowForm(!showForm);
  };


  const handleQuestionClick = (question) => {
    if (id === undefined) {
      newConversation(question, "pomeloservices_general", true, question);
    } else {
      navigate(`/chat/${id}?q=${question}`)
    }

  };

  return (
    <div className="messages-placeholder">
      <div className="messages-placeholder-header">
        <div className="messages-placeholder-logo">
          <img src={require('../../../assets/images/4d-logo.png')} alt="Pomelo Logo" />
          <h1>4Doctors</h1>
        </div>
      </div>
      <div className="messages-placeholder-main-content">
        <h2 className="messages-placeholder-main-question">{placeholder}</h2>
        <div className="messages-placeholder-collection-selector">
          {showForm && collections.length > 1 ? (
            <FormInput
              name={"collection-select"}
              type={"select"}
              label={"Colección"}
              options={collections.map((collection) => ({
                label: collection.name,
                value: collection.id,
              }))}
              placeholder={"Selecciona la colección"}
              helptext={"Colección de documentos"}
              initial_value={{
                label: current_collection?.name,
                value: current_collection?.id,
              }}
              onChange={(e) => handleCollectionChange(e.value)}
            />
          ) : (
            <h3>Colección: {current_collection?.verbose_name}</h3>
          )}
        </div>
        <div className="messages-placeholder-toggle-form">
          {!showForm && collections.length > 1 && (
              <button onClick={toggleFormVisibility}>
                {showForm ? "Seleccione la coleccion de su interés" : "Ver otras colecciones de contenidos"}
              </button>
          )}
        </div>

        <div className="messages-placeholder-questions-grid">
          {questions?.map((question, index) => (
            <div key={index} className="messages-placeholder-question-card" onClick={() => handleQuestionClick(question)}>
              <p>{question}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MessagesPlaceholder;
