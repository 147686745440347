import React, { useState } from 'react';
import './NewQueryButtonFullScreen.css';
import { FaPlus } from 'react-icons/fa';
import { usePostHog } from 'posthog-js/react';
import NewQueryForm from '../../forms/NewQueryForm/NewQueryForm';

const NewQueryButtonFullScreen = ({ loading }) => {
    const [showModal, setShowModal] = useState(false);
    const posthog = usePostHog();

    const handleOpenModal = () => {
        setShowModal(true);

        posthog?.capture('NewQuery - Inicio de nueva consulta', {
            action: 'open_modal_fullscreen',
            timestamp: new Date(),
        });
    };

    const handleCloseModal = () => setShowModal(false);
  
    return (
    <>
        <button 
            className={`new-query-button-FullScreen ${loading ? 'loading':''}`}
            disabled={loading}
            onClick={handleOpenModal}
        >
            <FaPlus className="icon" />
        </button>
        {showModal && (
        <NewQueryForm show={showModal} onClose={handleCloseModal} />
        )}
    </>

    );
};

export default NewQueryButtonFullScreen;
