import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/index.css';
import reportWebVitals from './reportWebVitals';
import { AppRouter } from './routes';
import { PreviewProvider } from './contexts/previewContext';
import { DocumentsProvider } from './contexts/documentsContext';
import { ConversationProvider } from './contexts/conversationContext';
import { HistoryProvider } from './contexts/historyContext';
import { UserProvider } from './contexts/userContext';
import { PostHogProvider} from 'posthog-js/react'
import { MetadatasProvider } from './contexts/metadatasContext';
import Footer from './components/containers/Footer/Footer';
import { CollectionsProvider } from './contexts/collectionsContext';
const root = ReactDOM.createRoot(document.getElementById('root'));
const options = {
  api_host: "https://eu.i.posthog.com",
}
const apiKey = "phc_Xre8rJvDLdQ5dRSxPFCn3PLTcRPzQGptD2zNSBD5FSK"

root.render(
  <React.StrictMode>
    <PostHogProvider apiKey={apiKey} options={options}>
    <UserProvider>
    <HistoryProvider>
    <PreviewProvider>
    <DocumentsProvider>
    <ConversationProvider>
    <MetadatasProvider>
    <CollectionsProvider>

      <AppRouter />
      <Footer></Footer>

    </CollectionsProvider>
    </MetadatasProvider>
    </ConversationProvider>
    </DocumentsProvider>
    </PreviewProvider>
    </HistoryProvider>
    </UserProvider>
    </PostHogProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
