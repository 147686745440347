// El primer parámetro del reducer es el estado actual, en este caso se renombra como documents.
export default function documentsReducer(documents, action) {
    switch (action.type) {
        case "reset": 
            return [];

            case "add": {
              const newDocuments = extractDocumentsFromMessage([action.responseMessage] || [])
              return { ...documents, ...newDocuments };
            }

            case "set": {
              const documents = extractDocumentsFromHistory(action.history)
              return documents;
            }

            case "select": {
              const newDocuments = selectDocuments(documents, action.selectedDocuments)
              return newDocuments
            }

            case "unselect": {
              const newDocuments = unselectDocuments(documents, action.selectedDocuments)
              return newDocuments
            }
            default:
            throw new Error("Unhadled action" + action.type);
    }
}

function extractDocumentsFromHistory(list) {
  let documentsDict = {};
  let index = 1; // Inicializamos el índice desde 1

  list.forEach(item => {
    let documentsInMsg = item.msg_metadata.documents;

    // Verificamos que `documentsInMsg` sea un array
    if (Array.isArray(documentsInMsg)) {
      documentsInMsg.forEach(doc => {
        // Solo agregamos el documento si aún no está en el diccionario
        if (doc.doc_id && !documentsDict[doc.doc_id]) {
          const newDoc = {
            content: doc.document,
            metadata: doc.metadata
          };
          documentsDict[doc.doc_id] = newDoc; // Asignamos el documento
          documentsDict[doc.doc_id].index = index++; // Ahora asignamos el índice
        }
      });
    }
  });

  return documentsDict; // Retornamos el diccionario de documentos
}

function extractDocumentsFromMessage(list) {
  let documentsDict = {};
  let index = 1; // Inicializamos el índice desde 1
  
  // Verificamos que `list` no sea `undefined` ni `null` y sea un array
  if (Array.isArray(list)) {
    list.forEach(item => {
      // Verificamos que `item` no sea `undefined` ni `null` y que tenga la propiedad `msg_metadata`
      if (item && item.msg_metadata && Array.isArray(item.msg_metadata.documents)) {
        let documentsInMsg = item.msg_metadata.documents;

        documentsInMsg.forEach(doc => {
          
          // Solo agregamos el documento si tiene un `doc_id` y no está en el diccionario
          if (doc && doc.doc_id && !documentsDict[doc.doc_id]) {
            const newDoc = {
              content: doc.document,
              metadata: doc.metadata
            };
            documentsDict[doc.doc_id] = newDoc; // Asignamos el documento
            documentsDict[doc.doc_id].index = index++; // Ahora asignamos el índice
          }
        });
      }
    });
  }

  return documentsDict; // Retornamos el diccionario de documentos
}

function selectDocuments(documents, selectedDocuments) {
  // Primero, aseguramos que todos los documentos estén desmarcados
  // Object.keys(documents).forEach(documentId => {
  //   documents[documentId].selected = false;
  // });
  // Ahora, marcamos los documentos seleccionados como 'selected: true'
  selectedDocuments.forEach(selectedDoc => {
    const documentId = selectedDoc.doc_id; // Asumimos que 'VALUE' es el atributo para buscar el documento
    // Si el documento con el 'VALUE' existe en el diccionario
    if (documents[documentId]) {
      // Añadimos el atributo 'selected: true' al documento correspondiente
      documents[documentId].selected = true;
    }
  });

  return documents;
}

function unselectDocuments(documents, selectedDocuments) {
  // Ahora, marcamos los documentos seleccionados como 'selected: true'
  selectedDocuments.forEach(selectedDoc => {
    const documentId = selectedDoc.doc_id; // Asumimos que 'VALUE' es el atributo para buscar el documento

    // Si el documento con el 'VALUE' existe en el diccionario
    if (documents[documentId]) {
      // Añadimos el atributo 'selected: true' al documento correspondiente
      documents[documentId].selected = false;
    }
  });

  return documents;
}
