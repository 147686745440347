import { useState } from "react";
import './IntentionButton.css'


const IntentionButton = ({ intention, intention_options, onSelect }) => {
    const [open, setOpen] = useState(false);
  
    const handleSelect = (key) => {
      setOpen(false);
      if (onSelect) {
        onSelect(key);
      }
      if (intention_options[key] && typeof intention_options[key].onClick === "function") {
        intention_options[key].onClick();
      }
    };
  
    return (
      <div className="intention-button-container">
        <button className="intention-button" onClick={() => setOpen(!open)}>
            <span className="button-icon">
                {intention_options[intention]?.icon}
            </span>
            <span className="button-text">
                {intention_options[intention]?.tooltip}
            </span>
        </button>

        {open && (
          <div className="dropdown-menu">
            {Object.keys(intention_options).map((key) => (
            <div
                key={key}
                className={`dropdown-item ${intention === key ? 'selected' : ''}`}
                onClick={() => handleSelect(key)}
            >
                <div className="dropdown-item-content">
                <div className="icon-tooltip">
                    {intention_options[key]?.icon}
                    <span className="tooltip-text">
                    {intention_options[key]?.tooltip}
                    </span>
                </div>
                <span className="description">
                    {intention_options[key]?.description}
                </span>
                </div>
            </div>
            ))}

          </div>
        )}
      </div>
    );
  };
  
  
  export default IntentionButton;